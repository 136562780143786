<template>
  <div>
    <!-- {{mainAsset}} -->
    <ag-grid-vue
      style="width: 100%; height: 450px"
      class="ag-theme-alpine"
      :columnDefs="columnDefs"
      :rowData="rowData"
      :rowSelection="rowSelection"
      :setQuickFilter="updateSearchQuery"
      :gridOptions="gridOptions"
      @grid-ready="onGridReady"
      suppressDragLeaveHidesColumns
      suppressMovableColumns
      checkboxSelection="true"
    >
    </ag-grid-vue>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { AgGridVue } from "ag-grid-vue";
import Cookie from "js-cookie";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import iconEditCheck from "../../../components/Table/components/iconEditCheck.vue";
import iconDeleteCheck from "../../../components/Table/components/iconDeleteCheck.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    AgGridVue,
    iconEditCheck,
    iconDeleteCheck,
  },
  props: {
    searchText: {
      type: String,
    },
  },
  data() {
    return {
      columnDefs: null,
      rowSelection: "multiple",
      gridOptions: {
        rowHeight: 50,
        defaultColDef: {
          resizable: true,
        },
      },
      gridApi: "sizeColumnsToFit",
      id_user: null,
    };
  },
  beforeMount() {
    this.columnDefs = [
      {
        headerName: this.$t("count.order"),
        field: "index",
        maxWidth: 110,
        valueGetter: "node.rowIndex + 1",
        getQuickFilterText: (params) => {
          return "";
        },
        cellClass: "text-center",
      },
      {
        headerName: this.$t("depreciation.code_asset"),
        field: "code_asset",
        sortable: true,
        minWidth: 200,
      },
      {
        headerName: this.$t("depreciation.name_asset"),
        field: "name_asset",
        sortable: true,
        minWidth: 200,
      },
      {
        headerName: this.$t("depreciation.code_acc"),
        field: "code_acc",
        sortable: true,
        minWidth: 200,
      },
      {
        headerName: this.$t("depreciation.name_acc"),
        field: "name_acc",
        sortable: true,
        minWidth: 200,
      },
      {
        headerName: this.$t("depreciation.code_acc_dp"),
        field: "code_acc_dp",
        sortable: true,
        minWidth: 200,
      },
      {
        headerName: this.$t("depreciation.name_acc_dp"),
        field: "name_acc_dp",
        sortable: true,
        minWidth: 200,
      },
      {
        headerName: this.$t("depreciation.edit_note_acc"),
        field: "edit",
        cellRenderer: "iconEditCheck",
        maxWidth: 130,
        suppressSizeToFit: true,
        cellClass: "text-center",
      },
      {
        headerName: this.$t("depreciation.delete"),
        field: "delete",
        cellRenderer: "iconDeleteCheck",
        maxWidth: 130,
        suppressSizeToFit: true,
        cellClass: "text-center",
      },
    ];
  },
  computed: {
    ...mapGetters({
      noteAccountingSelect: "noteAccountingSelect",
    }),

    rowData() {
      return this.noteAccountingSelect.sub_note_accountings.map(
        (sub_note_accountings) => {
          return {
            code_asset: sub_note_accountings.code_asset,
            name_asset: sub_note_accountings.name_asset,
            code_acc: sub_note_accountings.code_acc,
            name_acc: sub_note_accountings.name_acc,
            code_acc_dp: sub_note_accountings.code_acc_dp,
            name_acc_dp: sub_note_accountings.name_acc_dp,
            edit: { id_user: sub_note_accountings.sub_note_accounting_id },
            delete: sub_note_accountings,
          };
        }
      );
    },
  },
  watch: {
    searchText: function searchText() {
      this.updateSearchQuery(this.searchText);
    },
  },
  async mounted() {
    let cookie = Cookie.get("cookielogin");
    if (cookie) {
      let cookiejson = JSON.parse(cookie);
      this.id_user = cookiejson.id_user;
    }
    this.gridApi = this.gridOptions.api;
  },
  methods: {
    onGridReady() {
      this.gridApi.sizeColumnsToFit();
    },
    getSelectedRowData() {
      let selectedNodes = this.gridApi.getSelectedNodes();
      let selectedData = selectedNodes.map((node) => node.data);
      return selectedData;
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
      this.gridApi.refreshCells();
    },
  },
};
</script>

<style scoped>
* >>> .ag-header-cell-label {
  justify-content: center;
}
* >>> .ag-header-row-column {
  background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%);
  color: white;
}
* >>> .ag-theme-alpine {
  text-align: center;
}
</style>
